<template>
<div>
    <!-- Cancel Option Modal -->
    <div class="modal fade" id="cancelEditModal" tabindex="-1" aria-labelledby="cancelEditModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="cancelEditModalLabel">{{ $route.path == '/en' ? 'Are you sure?' : 'Yakin lu?' }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ $route.path == '/en' ? 'All changes that you have edited will be lost' : 'Semua perubahan yang telah anda edit akan hilang' }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary btn-sm" data-dismiss="modal" ref="modalClose">{{ $route.path == '/en' ? 'No' : 'Nggak' }}</button>
                    <button type="button" class="btn btn-danger btn-sm" @click="setNormalMode">{{ $route.path == '/en' ? 'Yes' : 'Yakin' }}</button>
                </div>
            </div>
        </div>
    </div>
    <div data-aos="fade-left" id="shortProfile">
        <div class="row">
            <div class="col-xl-6 shortImg-top zindex-999">
                <img src="@/assets/images/testing-new-tech.png" alt="4Fun" class="w-100">
            </div>
            <div class="col-xl-6 profile-text zindex-999">
                <template v-if="$route.path == '/en'">
                    <h3 class="content-heading">Short profile about me</h3>
                    <template v-if="authenticated">
                        <template v-if="profile.length == 0">
                            <createShortProfile/>
                        </template>
                        <template v-if="editMode">
                            <updateShortProfile :id="profile.id"/>
                        </template>
                        <template v-else>
                            <p v-html="profile.short_profile_en" @click="setEditMode"></p>
                        </template>
                    </template>
                    <template v-else>
                        <p v-html="profile.short_profile_en"></p>
                    </template>
                </template>
                <template v-else>
                    <h3 class="content-heading">Profil singkat tentang saya</h3>
                    <template v-if="authenticated">
                        <template v-if="profile.length == 0">
                            <createShortProfile/>
                        </template>
                        <template v-if="editMode">
                            <updateShortProfile :id="profile.id"/>
                        </template>
                        <template v-else>
                            <p v-html="profile.short_profile_id" @click="setEditMode"></p>
                        </template>
                    </template>
                    <template v-else>
                        <p v-html="profile.short_profile_id"></p>
                    </template>
                </template>
            </div>
            <div class="col-xl-6 shortImg-bottom zindex-999">
                <img src="@/assets/images/testing-new-tech.png" alt="4Fun" class="w-100">
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import {bus} from '../../main'
import {mapGetters} from 'vuex'
import froalaConfig from '../../froalaConfig'
import createShortProfile from './createShortProfile'
import updateShortProfile from './updateShortProfile'
export default {
    data () {
        return {
            config: froalaConfig,
            editMode: false,
            form: {
                short_profile_en: '',
                short_profile_id: ''
            }
        }
    },
    mounted(){
        bus.$on('normalMode', (status) => {
            this.editMode = status
            this.form.short_profile_en = this.profile.short_profile_en
            this.form.short_profile_id = this.profile.short_profile_id
        })
        this.getProfile()
    },
    components: {createShortProfile, updateShortProfile},
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
            profile: 'profile/profile'
        })
    },
    methods: {
        setEditMode(){
            this.editMode = true
        },
        setNormalMode(){
            this.editMode = false
            this.$refs.modalClose.click()
        },
        getProfile(){
            this.$store.dispatch('profile/getProfile')
        },
        update(){
            this.form.short_profile_en = this.profile.short_profile_en
            this.form.short_profile_id = this.profile.short_profile_id
            this.$store.dispatch('profile/updateProfile', [this.profile.id, this.form])
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/shortProfile.scss';
</style>