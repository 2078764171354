<template>
<div>
    <p class="text-white float-left">{{ $route.path == '/en' ? 'Are you sure to delete?' : 'Yakin lu mau hapus?' }}
        <button class="btn btn-danger btn-sm py-0 px-1 ml-2 d-flex float-right" @click="submit" :disabled="deleteLoading">
            {{ $route.path == '/en' ? 'Yes' : 'Yakin' }}
            <template v-if="deleteLoading">
                <passingThree/>
            </template>
        </button>
    </p>
    <br class="break">
</div>
</template>

<script>
import {mapGetters} from 'vuex'
import passingThree from '@/components/loadings/passingThree'
export default {
    props: ['experienceId'],
    components: {passingThree},
    computed: {
        ...mapGetters({
            deleteLoading: 'deleteLoading',
        })
    },
    methods: {
        submit(){
            this.$store.dispatch('experience/deleteExperience', this.$props.experienceId)
        }
    }
}
</script>