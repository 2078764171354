<template>
    <div>
        <a href="#collapseDelete" class="badge badge-danger" type="button" data-toggle="collapse" data-target="#collapseDelete" aria-expanded="false" aria-controls="collapseDelete"><i class="fas fa-trash-alt mr-1"></i>{{ $route.path == '/en' ? 'Delete Skill' : 'Hapus Skill' }}</a>
        <div class="collapse mt-2" id="collapseDelete">
            <p class="text-sm d-flex">{{ $route.path == '/en' ? 'Are you sure?' : 'Yakin lu?' }}
                <span class="bg-light cursor-pointer px-2 rounded mx-2 float-left" data-toggle="collapse" data-target="#collapseDelete">{{ $route.path == '/en' ? 'no' : 'nggak' }}</span> 
                <span class="bg-danger cursor-pointer px-2 rounded d-flex float-left" @click="submit" :disabled="deleteLoading">
                    {{  $route.path == '/en' ? 'yes' : 'yoii'  }}
                    <template v-if="deleteLoading">
                        <passingThree/>
                    </template>
                </span>
            </p>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import passingThree from '@/components/loadings/passingThree'
export default {
    props: ['skillId'],
    components: {passingThree},
    computed: {
        ...mapGetters({
            deleteLoading: 'deleteLoading',
        })
    },
    methods: {
        submit(){
            this.$store.dispatch('skill/deleteSkill', this.$props.skillId).then(response => {
                if(response.status === 200){
                    this.$parent.$refs.modalClose.click()
                }
            })
        }
    }
}
</script>