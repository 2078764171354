<template>
<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="updateModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="updateModalLabel">Edit Skill</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <template v-if="render">
                    <div class="d-flex align-items-center">
                        <strong>Loading...</strong>
                        <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                    </div>
                </template>
                <template v-else>
                    <form action="#" @submit.prevent="submit">
                        <div class="row">
                            <div class="col-5 d-flex align-items-center">
                                <center>
                                    <label for="fileUpdate">
                                        <img :src="image" alt="plus" class="w-75 rounded cursor-pointer" title="Choose Icon" v-if='image'>
                                        <img :src="`${baseURL}/storage/images/skills/${skill.icon}`" :alt="skill.name" class="w-75 rounded cursor-pointer" title="Choose Icon" v-else>
                                    </label>
                                </center>
                                <input type="file" class="d-none" id="fileUpdate" v-on:change="onImageChange">
                            </div>
                            <div class="col-7">
                                <div class="form-group">
                                    <label for="">{{ $route.path == '/en' ? 'Skill name' : 'Skill' }}: </label>
                                    <input type="text" class="form-control" v-model="skill.name" :class="{'is-invalid': formErrors.name}">
                                    <div class="invalid-feedback" v-if="formErrors.name">{{formErrors.name[0]}}</div>
                                </div>
                                <div class="form-group">
                                    <label for="">{{ $route.path == '/en' ? 'Ability' : 'Kemampuan' }}: </label>
                                    <input type="text" class="form-control" v-model="skill.ability" :class="{'is-invalid': formErrors.ability}" v-if="skill.ability">
                                    <input type="text" class="form-control" v-model="form.ability" :class="{'is-invalid': formErrors.ability}" v-else-if="form.ability || skill.ability == null || skill.ability == ''">
                                    <input type="text" class="form-control" v-model="skill.ability" :class="{'is-invalid': formErrors.ability}" v-else-if="skill.ability & form.ability">
                                    <div class="invalid-feedback" v-if="formErrors.ability">{{formErrors.ability[0]}}</div>
                                    <p class="text-secondary text-sm" v-else><i class="fas fa-exclamation-circle mr-1"></i>{{ $route.path == '/en' ? 'ability isnt mandatory' : 'kemampuan gk wajib diisi' }}</p>
                                </div>
                                <div class="form-group">
                                    <label for="">{{ $route.path == '/en' ? 'How long experience?' : 'Berapa lama pengalaman?' }}</label>
                                    <input type="text" class="form-control" v-model="skill.experience_en" :class="{'is-invalid': formErrors.experience}" v-if="$route.path == '/en'">
                                    <input type="text" class="form-control" v-model="skill.experience_id" :class="{'is-invalid': formErrors.experience}" v-else>
                                    <div class="invalid-feedback" v-if="formErrors.experience">{{formErrors.experience[0]}}</div>
                                </div>
                                <deleteSkill :skillId="skillId"/>
                            </div>
                        </div>
                        <hr>
                        <div class="float-right">
                            <button type="button" class="btn btn-outline-secondary btn-sm mr-2 float-left" data-dismiss="modal" ref="modalClose">{{ $route.path == '/en' ? 'Close' : 'Tutup' }}</button>
                            <button type="submit" class="btn btn-primary btn-sm d-flex float-left" :disabled="btnLoading">
                                {{ $route.path == '/en' ? 'Save' : 'Simpan' }}
                                <template v-if="btnLoading">
                                    <passingThree/>
                                </template>
                            </button>
                        </div>
                    </form>
                </template>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import config from '@/config'
import deleteSkill from './deleteSkill'
import passingThree from '@/components/loadings/passingThree'

export default {
    props: ['skillId'],
    data(){
        return{
            form: {
                icon: '',
                ability: '',
            },
            image: '',
            baseURL: config.backendBaseURL,
        }
    },
    components: {deleteSkill, passingThree},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
            render: 'render',
            skill: 'skill/skill'
        })
    },
    watch: {
        skillId: function(){
            this.$store.dispatch('skill/showSkill', this.$props.skillId)
            this.image = null
        }
    },
    methods:{
        onImageChange(e){
            let file = e.target.files || e.dataTransfer.files;
            this.form.icon = file[0];
            if(!file.length){
                return
            }
            this.createImage(file[0]);
        },
        createImage(file){
            let reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result
            }
            reader.readAsDataURL(file)
        },
        submit(){
            const data = new FormData()
            data.append('icon', this.form.icon)
            data.append('name', this.skill.name)
            
            // Set Ability
            if(this.skill.ability){
                data.append('ability', this.skill.ability)
            }else if(this.form.ability || this.skill.ability == null || this.skill.ability == ''){
                data.append('ability', this.form.ability)
            }else if(this.skill.ability && this.form.ability)
                data.append('ability', this.skill.ability)

            // Set Experience
            if(this.$router.currentRoute.path == '/en'){
                data.append('experience', this.skill.experience_en)
            }else{
                data.append('experience', this.skill.experience_id)
            }

            this.$store.dispatch('skill/updateSkill', [this.$props.skillId, data]).then(response => {
                if(response.status === 200){
                    this.$refs.modalClose.click()
                }
            })
        }
    }
}
</script>