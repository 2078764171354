<template>
<div class="modal fade" id="createModal" tabindex="-1" role="dialog" aria-labelledby="createModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="createModalLabel">New Skill</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form action="#" @submit.prevent="submit">
                    <div class="row">
                        <div class="col-5 d-flex align-items-center">
                            <center>
                                <label for="file">
                                    <img :src="image" alt="plus" class="w-75 rounded cursor-pointer" title="Choose Icon" v-if='image'>
                                    <img src="@/assets/images/icon/add.png" alt="plus" class="w-75 rounded cursor-pointer" title="Choose Icon" v-else>
                                </label>
                            </center>
                            <input type="file" class="d-none" id="file" v-on:change="onImageChange">
                        </div>
                        <div class="col-7">
                            <div class="form-group">
                                <label for="">{{ $route.path == '/en' ? 'Skill name' : 'Skill' }}: </label>
                                <input type="text" class="form-control" v-model="form.name" :class="{'is-invalid': formErrors.name}">
                                <div class="invalid-feedback" v-if="formErrors.name">{{formErrors.name[0]}}</div>
                            </div>
                            <div class="form-group">
                                <label for="">{{ $route.path == '/en' ? 'Ability' : 'Kemampuan' }}: </label>
                                <input type="text" class="form-control" v-model="form.ability" :class="{'is-invalid': formErrors.ability}">
                                <div class="invalid-feedback" v-if="formErrors.ability">{{formErrors.ability[0]}}</div>
                                <p class="text-secondary text-sm" v-else><i class="fas fa-exclamation-circle mr-1"></i>{{ $route.path == '/en' ? 'ability isnt mandatory' : 'kemampuan gk wajib diisi' }}</p>
                            </div>
                            <div class="form-group">
                                <label for="">{{ $route.path == '/en' ? 'How long experience?' : 'Berapa lama pengalaman?' }}</label>
                                <input type="text" class="form-control" v-model="form.experience" :class="{'is-invalid': formErrors.experience}">
                                <div class="invalid-feedback" v-if="formErrors.experience">{{formErrors.experience[0]}}</div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="float-right">
                        <button type="button" class="btn btn-outline-secondary btn-sm mr-2 float-left" data-dismiss="modal" ref="modalClose">Close</button>
                        <button type="submit" class="btn btn-primary btn-sm d-flex float-left" :disabled="btnLoading">
                            Submit
                            <template v-if="btnLoading">
                                <passingThree/>
                            </template>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import passingThree from '@/components/loadings/passingThree'
export default {
    data(){
        return{
            form: {
                icon: '',
                name: '',
                ability: '',
                experience: ''
            },
            image: '',
        }
    },
    components: {passingThree},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
        })
    },
    methods:{
        onImageChange(e){
            let file = e.target.files || e.dataTransfer.files;
            this.form.icon = file[0];
            if(!file.length){
                return
            }
            this.createImage(file[0]);
        },
        createImage(file){
            let reader = new FileReader();
            reader.onload = (e) => {
                this.image = e.target.result
            }
            reader.readAsDataURL(file)
        },
        submit(){
            const data = new FormData()
            data.append('icon', this.form.icon)
            data.append('name', this.form.name)
            // Set Ability
            if(!this.form.ability){
                this.form.ability = ''
                data.append('ability', this.form.ability)
            }else{
                data.append('ability', this.form.ability)
            }
            data.append('experience', this.form.experience)

            this.$store.dispatch('skill/storeSkill', data).then(response => {
                if(response.status === 200){
                    this.$refs.modalClose.click()
                    this.form = {}
                    this.image = ''
                }
            })
        }
    }
}
</script>