<template>
    <div class="intro position-relative d-flex align-items-center zindex-999" id="intro">
        <div class="container">
            <div class="row">
                <div class="col-md-3 p-0 intro-image zindex-999" data-aos="fade-up">
                    <img src="../assets/images/me2.png" alt="Muhamad Ndaru">
                </div>
                <div class="col-md-9 text-intro d-flex align-items-center px-3" data-aos="fade-down">
                    <p class="zindex-999">
                        {{ $route.path == '/en' ? 'Hello There' : 'Halo, Apa Kabar' }} 👋 <br>
                        <router-link to="login" class="text">{{ $route.path == '/en' ? 'I\'m, ' : 'Saya ' }}</router-link>
                        <b>Muhamad Ndaru Hestiafin</b>
                        <br>
                        <vue-typed-js :strings="typeData" :loop="true" :startDelay="1000" :backSpeed="30" :typeSpeed="50" class="typed-wrapper d-flex">
                            <span class="typed">and i'm <span class="typing"></span></span>
                        </vue-typed-js>
                        <a href="#" class="btn btn-sm btn-download mr-2" @click="downloadCV">
                            <div class="d-flex">
                                <span v-if="btnLoading">
                                    <pacmanWhite />
                                </span>
                                <span v-else>
                                    <svg data-v-22b83c6c="" class="svg-inline--fa fa-file-pdf fa-w-12 mr-2" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file-pdf" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg="">
                                        <path fill="currentColor" d="M181.9 256.1c-5-16-4.9-46.9-2-46.9 8.4 0 7.6 36.9 2 46.9zm-1.7 47.2c-7.7 20.2-17.3 43.3-28.4 62.7 18.3-7 39-17.2 62.9-21.9-12.7-9.6-24.9-23.4-34.5-40.8zM86.1 428.1c0 .8 13.2-5.4 34.9-40.2-6.7 6.3-29.1 24.5-34.9 40.2zM248 160h136v328c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V24C0 10.7 10.7 0 24 0h200v136c0 13.2 10.8 24 24 24zm-8 171.8c-20-12.2-33.3-29-42.7-53.8 4.5-18.5 11.6-46.6 6.2-64.2-4.7-29.4-42.4-26.5-47.8-6.8-5 18.3-.4 44.1 8.1 77-11.6 27.6-28.7 64.6-40.8 85.8-.1 0-.1.1-.2.1-27.1 13.9-73.6 44.5-54.5 68 5.6 6.9 16 10 21.5 10 17.9 0 35.7-18 61.1-61.8 25.8-8.5 54.1-19.1 79-23.2 21.7 11.8 47.1 19.5 64 19.5 29.2 0 31.2-32 19.7-43.4-13.9-13.6-54.3-9.7-73.6-7.2zM377 105L279 7c-4.5-4.5-10.6-7-17-7h-6v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-74.1 255.3c4.1-2.7-2.5-11.9-42.8-9 37.1 15.8 42.8 9 42.8 9z"></path>
                                    </svg>
                                </span>
                                Download Resume
                            </div>
                        </a>
                        <a href="#shortProfile" class="btn btn-sm btn-see">{{ $route.path == '/en' ? 'Explore Me' : 'Yuk Lihat' }}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import pacmanWhite from "./loadings/pacmanWhite";
export default {
    data() {
        return {
            typeData: ["a student", "full stack web developer", "from Jakarta"],
        };
    },
    components: { pacmanWhite },
    computed: {
        ...mapGetters({
            btnLoading: "btnLoading",
        }),
    },
    methods: {
        downloadCV() {
            this.$store.dispatch("downloadCV")
            // .then((response) => {
                // console.log(response);
                // let blob = new Blob([response.data], { type: 'application/pdf' })
                // let link = document.createElement('a')
                // link.href = window.URL.createObjectURL(blob)
                // link.download = 'ndaruhes-CV.pdf'
                // link.click()
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/intro.scss";
</style>