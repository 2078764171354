<template>
    <div>
        <!-- UPDATE ACHIEVEMENT EN -->
        <form action="#" @submit.prevent="submit" v-if="$route.path == '/en'">
            <div class="form-row">
                <div class="form-group col-8">
                    <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.achievement}" placeholder="Type achievement..." v-model="form.achievement">
                    <span class="float-left mt-1" v-if="btnLoading">
                        <ringWhite/>
                    </span>
                    <div class="invalid-feedback" v-if="formErrors.achievement">{{formErrors.achievement[0]}}</div>
                </div>
                <div class="form-group col-4">
                    <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.years}" placeholder="Years..." v-model="form.years">
                    <div class="invalid-feedback" v-if="formErrors.years">{{formErrors.years[0]}}</div>
                </div>
            </div>
            <button type="submit" class="d-none">Save</button>
        </form>

        <!-- UPDATE ACHIEVEMENT ID -->
        <form action="#" @submit.prevent="submit" v-else>
            <div class="form-row">
                <div class="form-group col-8">
                    <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.achievement}" placeholder="Tulis pengalaman..." v-model="form.achievement">
                    <span class="float-left mt-1" v-if="btnLoading">
                        <ringWhite/>
                    </span>
                    <div class="invalid-feedback" v-if="formErrors.achievement">{{formErrors.achievement[0]}}</div>
                </div>
                <div class="form-group col-4">
                    <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.years}" placeholder="Tahun..." v-model="form.years">
                    <div class="invalid-feedback" v-if="formErrors.years">{{formErrors.years[0]}}</div>
                </div>
            </div>
            <button type="submit" class="d-none">Simpan</button>
        </form>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ringWhite from '@/components/loadings/ringWhite'
import $ from 'jquery'
export default {
    props: ['achievementId', 'achievement', 'years'],
    data(){
        return{
            form: {
                achievement: '',
                years: ''
            }
        }
    },
    components: {ringWhite},
    created(){
        this.setData();
    },
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
        })
    },
    watch: {
        achievement: function(){
            this.form.achievement = this.$props.achievement
            this.form.years = this.$props.years
        }
    },
    methods: {
        setData(){
            this.form.achievement = this.$props.achievement
            this.form.years = this.$props.years
        },
        submit(){
            this.$store.dispatch('experience/updateAchievement', [this.$props.achievementId, this.form]).then(response => {
                if(response.status === 200){
                    $('.collapse').collapse('hide')
                    this.form.achievement = response.data.new_achievement
                    this.form.years = response.data.new_achievement_years
                }
            })
        }
    }
}
</script>