<template>
    <div class="footer position-relative d-flex align-items-center justify-content-center">
        <p class="text-center zindex-999" data-aos="zoom-in-up">
            <template v-if="$route.path == '/en'">
                <b>I'm Ready!!</b> 
                <br>
                Feel free to contact me <i class="far fa-smile ml-1"></i>
            </template>
            <template v-else>
                <b>Saya Siap!!</b> 
                <br>
                Jangan ragu untuk menghubungi saya <i class="far fa-smile ml-1"></i>
            </template>
            <br>
            <a href="mailto:muhamad.ndaru@gmail.com" target="_blank" class="btn btn-gmail contact-btn rounded-circle shadow" title="Gmail">
                <i class="fas fa-envelope"></i>
            </a>
            &nbsp;
            <a href="https://api.whatsapp.com/send?phone=6285717084803" target="_blank" class="btn btn-whatsapp contact-btn rounded-circle shadow" title="Whatsapp">
                <i class="fab fa-whatsapp"></i>
            </a>
        </p>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/feelFree.scss';
</style>