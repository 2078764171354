<template>
  <div class="wrapper">
    <Navbar/>
    <div class="content-wrapper">
      <Intro/>
      <div class="container">
        <ShortProfile/>
        <Skills/>
        <Experiences/>
      </div>
      <FeelFree/>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Intro from '../components/Intro'
import ShortProfile from '@/components/shortProfile/ShortProfile'
import Skills from '../components/skills/Skills'
import Experiences from '@/components/experiences/Experiences'
import FeelFree from '../components/FeelFree'

export default {
  title: 'Muhamad Ndaru | Portfolio',
  components: { Navbar, Footer, Intro, ShortProfile, Skills, FeelFree, Experiences },
}
</script>
