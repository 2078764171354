<template>
    <div>
        <form action="#" @submit.prevent="store">
            <template v-if="$route.path == '/en'">
                <froala :config="config" v-model="form.short_profile_en"></froala>
                <p class="text-danger text-sm" v-if="formErrors.short_profile_en"><i class="fas fa-exclamation-circle mr-1"></i> {{formErrors.short_profile_en[0]}}</p>
                <button type="submit" class="btn btn-sm badge-bgColor px-3 d-flex float-left" :disabled="btnLoading">
                    Save
                    <template v-if="btnLoading">
                        <passingThree/>
                    </template>
                </button>
            </template>
            <template v-else>
                <froala :config="config" v-model="form.short_profile_id"></froala>
                <p class="text-danger text-sm" v-if="formErrors.short_profile_id"><i class="fas fa-exclamation-circle mr-1"></i> {{formErrors.short_profile_id[0]}}</p>
                <button type="submit" class="btn btn-sm badge-bgColor px-3 d-flex float-left" :disabled="btnLoading">
                    Simpan
                    <template v-if="btnLoading">
                        <passingThree/>
                    </template>
                </button>
            </template>
        </form>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import froalaConfig from '../../froalaConfig'
import passingThree from '@/components/loadings/passingThree'
export default {
    data(){
        return {
            config: froalaConfig,
            form: {
                short_profile_en: '',
                short_profile_id: ''
            }
        }
    },
    components: {passingThree},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
        })
    },
    methods: {
        store(){
            this.$store.dispatch('profile/storeProfile', this.form)
        }
    }
}
</script>