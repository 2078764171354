<template>
    <div class="5" id="points">
        <div class="row">
            <!-- ENGLISH -->
            <template v-if="$route.path == '/en'">
                <!-- Experience En -->
                <div class="col-xl-6" data-aos="fade-up">
                    <div class="col-md-12 point experiences">
                        <p class="mb-3"><i class="fas fa-paper-plane mr-2"></i> Experiences</p>
                        <div v-for="experience in experiences" :key="experience.id">
                            <div class="point-item">
                                <i class="fas fa-hand-point-right"></i>
                                {{ experience.experience_en }} ({{ experience.years }})
                                <template v-if="authenticated">
                                    <span class="ml-3 cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseDeleteExperience'+experience.id" aria-expanded="false" :aria-controls="'collapseDeleteExperience'+experience.id" @click="closeAllCollapse">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                    <span class="cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseUpdateExperience'+experience.id" aria-expanded="false" :aria-controls="'collapseUpdateExperience'+experience.id" @click="closeAllCollapse">
                                        <i class="fas fa-pencil-alt"></i>
                                    </span>
                                </template>
                            </div>
                            <template v-if="authenticated">
                                <div class="collapse mt-1" :id="'collapseUpdateExperience'+experience.id">
                                    <updateExperience :experienceId="experience.id" :experience="$route.path == '/en' ? experience.experience_en : experience.experience_id" :years="experience.years" />
                                </div>
                                <div class="collapse mt-1" :id="'collapseDeleteExperience'+experience.id">
                                    <deleteExperience :experienceId="experience.id" />
                                </div>
                            </template>
                        </div>
                        <template v-if="authenticated">
                            <div class="point-item" type="button" data-toggle="collapse" data-target="#experienceEnCollapse" aria-expanded="false" aria-controls="experienceEnCollapse"><i class="fas fa-plus mr-2"></i>Create New Experience</div>
                            <createExperience />
                        </template>
                    </div>
                </div>

                <!-- Achievement En -->
                <div class="col-xl-6" data-aos="fade-down">
                    <div class="col-md-12 point achievements">
                        <p class="mb-3"><i class="fas fa-trophy mr-2"></i> Achievements</p>
                        <div v-for="achievement in achievements" :key="achievement.id">
                            <div class="point-item">
                                <i class="fas fa-hand-point-right"></i>
                                {{ achievement.achievement_en }} ({{ achievement.years }})
                                <template v-if="authenticated">
                                    <span class="ml-3 cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseDeleteAchievement'+achievement.id" aria-expanded="false" :aria-controls="'collapseDeleteAchievement'+achievement.id" @click="closeAllCollapse">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                    <span class="cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseUpdateAchievement'+achievement.id" aria-expanded="false" :aria-controls="'collapseUpdateAchievement'+achievement.id" @click="closeAllCollapse">
                                        <i class="fas fa-pencil-alt"></i>
                                    </span>
                                </template>
                            </div>
                            <template v-if="authenticated">
                                <div class="collapse mt-1" :id="'collapseUpdateAchievement'+achievement.id">
                                    <updateAchievement :achievementId="achievement.id" :achievement="$route.path == '/en' ? achievement.achievement_en : achievement.achievement_id" :years="achievement.years" />
                                </div>
                                <div class="collapse mt-1" :id="'collapseDeleteAchievement'+achievement.id">
                                    <deleteAchievement :achievementId="achievement.id" />
                                </div>
                            </template>
                        </div>
                        <template v-if="authenticated">
                            <div class="point-item" type="button" data-toggle="collapse" data-target="#achievementEnCollapse" aria-expanded="false" aria-controls="achievementEnCollapse"><i class="fas fa-plus mr-2"></i>Make New Achievements</div>
                            <createAchievement />
                        </template>
                    </div>
                </div>
            </template>

            <!-- INDONESIAN -->
            <template v-else>
                <!-- Experience Id -->
                <div class="col-xl-6" data-aos="fade-up">
                    <div class="col-md-12 point experiences">
                        <p class="mb-3"><i class="fas fa-paper-plane mr-2"></i> Pengalaman</p>
                        <div v-for="experience in experiences" :key="experience.id">
                            <div class="point-item">
                                <i class="fas fa-hand-point-right"></i>
                                {{ experience.experience_id }} ({{ experience.years }})
                                <template v-if="authenticated">
                                    <span class="ml-3 cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseDeleteExperience'+experience.id" aria-expanded="false" :aria-controls="'collapseDeleteExperience'+experience.id" @click="closeAllCollapse">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                    <span class="cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseUpdateExperience'+experience.id" aria-expanded="false" :aria-controls="'collapseUpdateExperience'+experience.id" @click="closeAllCollapse">
                                        <i class="fas fa-pencil-alt"></i>
                                    </span>
                                </template>
                            </div>
                            <template v-if="authenticated">
                                <div class="collapse mt-1" :id="'collapseUpdateExperience'+experience.id">
                                    <updateExperience :experienceId="experience.id" :experience="$route.path == '/en' ? experience.experience_en : experience.experience_id" :years="experience.years" />
                                </div>
                                <div class="collapse mt-1" :id="'collapseDeleteExperience'+experience.id">
                                    <deleteExperience :experienceId="experience.id" />
                                </div>
                            </template>
                        </div>
                        <template v-if="authenticated">
                            <div class="point-item" type="button" data-toggle="collapse" data-target="#experienceIdCollapse" aria-expanded="false" aria-controls="experienceIdCollapse"><i class="fas fa-plus mr-2"></i>Buat Pengalaman Baru</div>
                            <createExperience />
                        </template>
                    </div>
                </div>

                <!-- Achievement Id -->
                <div class="col-xl-6" data-aos="fade-down">
                    <div class="col-md-12 point achievements">
                        <p class="mb-3"><i class="fas fa-trophy mr-2"></i> Prestasi</p>
                        <div v-for="achievement in achievements" :key="achievement.id">
                            <div class="point-item">
                                <i class="fas fa-hand-point-right"></i>
                                {{ achievement.achievement_id }} ({{ achievement.years }})
                                <template v-if="authenticated">
                                    <span class="ml-3 cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseDeleteAchievement'+achievement.id" aria-expanded="false" :aria-controls="'collapseDeleteAchievement'+achievement.id" @click="closeAllCollapse">
                                        <i class="fas fa-trash-alt"></i>
                                    </span>
                                    <span class="cursor-pointer float-right" data-toggle="collapse" :data-target="'#collapseUpdateAchievement'+achievement.id" aria-expanded="false" :aria-controls="'collapseUpdateAchievement'+achievement.id" @click="closeAllCollapse">
                                        <i class="fas fa-pencil-alt"></i>
                                    </span>
                                </template>
                            </div>
                            <template v-if="authenticated">
                                <div class="collapse mt-1" :id="'collapseUpdateAchievement'+achievement.id">
                                    <updateAchievement :achievementId="achievement.id" :achievement="$route.path == '/en' ? achievement.achievement_en : achievement.achievement_id" :years="achievement.years" />
                                </div>
                                <div class="collapse mt-1" :id="'collapseDeleteAchievement'+achievement.id">
                                    <deleteAchievement :achievementId="achievement.id" />
                                </div>
                            </template>
                        </div>
                        <template v-if="authenticated">
                            <div class="point-item" type="button" data-toggle="collapse" data-target="#achievementIdCollapse" aria-expanded="false" aria-controls="achievementIdCollapse"><i class="fas fa-plus mr-2"></i>Buat Prestasi Baru</div>
                            <createAchievement />
                        </template>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";

// Experience's Components
import createExperience from "./createExperience";
import deleteExperience from "./deleteExperience";
import updateExperience from "./updateExperience";

// Achievement's Components
import createAchievement from "../achievements/createAchievement";
import deleteAchievement from "../achievements/deleteAchievement";
import updateAchievement from "../achievements/updateAchievement";

export default {
    mounted() {
        this.getExperiences();
        this.getAchievements();
    },
    components: {
        createExperience,
        deleteExperience,
        updateExperience,
        createAchievement,
        deleteAchievement,
        updateAchievement,
    },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            experiences: "experiences",
            achievements: "achievements",
        }),
    },
    methods: {
        closeAllCollapse() {
            $(".collapse").collapse("hide");
        },
        getExperiences() {
            this.$store.dispatch("getExperiences");
        },
        getAchievements() {
            this.$store.dispatch("getAchievements");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/experiences.scss";
</style>