<template>
    <div>
        <!-- CREATE ACHIEVEMENT EN -->
        <div class="collapse" id="achievementEnCollapse" v-if="$route.path == '/en'">
            <form action="#" @submit.prevent="submitAchievement">
                <div class="form-row">
                    <div class="form-group col-8">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.achievement}" placeholder="Type achievement..." v-model="formAchievement.achievement">
                        <span class="float-left mt-1" v-if="btnLoading">
                            <ringWhite/>
                        </span>
                        <div class="invalid-feedback" v-if="formErrors.achievement">{{formErrors.achievement[0]}}</div>
                    </div>

                    <div class="form-group col-4">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.years}" placeholder="Years..." v-model="formAchievement.years">
                        <div class="invalid-feedback" v-if="formErrors.years">{{formErrors.years[0]}}</div>
                    </div>
                </div>
                <button type="submit" class="d-none">Save</button>
            </form>
        </div>

        <!-- CREATE ACHIEVEMENT ID -->
        <div class="collapse" id="achievementIdCollapse" v-else>
            <form action="#" @submit.prevent="submitAchievement">
                <div class="form-row">
                    <div class="form-group col-8">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.achievement}" placeholder="Tulis prestasi..." v-model="formAchievement.achievement">
                        <span class="float-left mt-1" v-if="btnLoading">
                            <ringWhite/>
                        </span>
                        <div class="invalid-feedback" v-if="formErrors.achievement">{{formErrors.achievement[0]}}</div>
                    </div>

                    <div class="form-group col-4">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.years}" placeholder="Tahun..." v-model="formAchievement.years">
                        <div class="invalid-feedback" v-if="formErrors.years">{{formErrors.years[0]}}</div>
                    </div>
                </div>
                <button type="submit" class="d-none">Simpan</button>
            </form>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ringWhite from '@/components/loadings/ringWhite'
import $ from 'jquery'
export default {
    data(){
        return{
            formAchievement: {
                achievement: '',
                years: ''
            }
        }
    },
    components: {ringWhite},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
        })
    },
    methods: {
        submitAchievement(){
            this.$store.dispatch('experience/storeAchievement', this.formAchievement).then(response => {
                if(response.status === 200){
                    $('.collapse').collapse('hide')
                    this.formAchievement = {}
                }
            })
        }
    }
}
</script>