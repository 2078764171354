export default{
    // "key": "INSERT-YOUR-FROALA-KEY-HERE",
    "placeholderText": "Short profile...",
    "events": {},
    "toolbarInline": true,
    "toolbarButtons": {
        "moreText": {
            "buttons": [
                "bold",
                "italic",
                "underline",
                "insertLink",
                "strikeThrough",
                "subscript",
                "superscript",
                "fontFamily",
                "fontSize",
                "textColor",
                "backgroundColor",
                "clearFormatting",
            ],
            "buttonsVisible": 3,
            "align": "left"
        },
        "moreParagraph": {
            "buttons": [
                "formatOLSimple",
                "formatOL",
                "formatUL",
                "outdent",
                "indent"
            ],
            "buttonsVisible": 3,
            "align": "left"
        },
        "undefined": {
            "buttons": [
                "insertVideo",
                "insertHR"
            ],
            "buttonsVisible": 2,
            "align": "left"
        },
        "linkAttributes": {},
        "moreMisc": {
            "buttons": [
                "undo",
                "redo",
                "fullscreen",
                "selectAll"
            ],
            "buttonsVisible": 2,
            "align": "right"
        },
        "showMoreButtons": true
    },
    "language": "id"
}