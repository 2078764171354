<template>
    <div>
        <form action="#" @submit.prevent="update">
            <template v-if="$route.path == '/en'">
                <froala :config="config" v-model="form.short_profile_en"></froala>
                <p class="text-danger text-sm" v-if="formErrors.short_profile_en"><i class="fas fa-exclamation-circle mr-1"></i> {{formErrors.short_profile_en[0]}}</p>
                <button type="button" class="btn btn-sm badge-outlineColor px-3 float-left mr-2" @click="setNormalModeOption">Close</button>
                <button type="submit" class="btn btn-sm badge-bgColor px-3 d-flex float-left" :disabled="btnLoading">
                    Save
                    <template v-if="btnLoading">
                        <passingThree/>
                    </template>
                </button>
            </template>
            <template v-else>
                <froala :config="config" v-model="form.short_profile_id"></froala>
                <p class="text-danger text-sm" v-if="formErrors.short_profile_id"><i class="fas fa-exclamation-circle mr-1"></i> {{formErrors.short_profile_id[0]}}</p>
                <button type="button" class="btn btn-sm badge-outlineColor px-3 float-left mr-2" @click="setNormalModeOption">Tutup</button>
                <button type="submit" class="btn btn-sm badge-bgColor px-3 d-flex float-left" :disabled="btnLoading">
                    Simpan
                    <template v-if="btnLoading">
                        <passingThree/>
                    </template>
                </button>
            </template>
        </form>
    </div>
</template>

<script>
import {bus} from '../../main'
import {mapGetters} from 'vuex'
import froalaConfig from '../../froalaConfig'
import passingThree from '@/components/loadings/passingThree'
import $ from 'jquery'
export default {
    props: ['id'],
    data () {
        return {
            config: froalaConfig,
            form: {
                short_profile_en: '',
                short_profile_id: ''
            }
        }
    },
    components: {passingThree},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
            profile: 'profile/profile'
        })
    },
    created(){
        this.form.short_profile_en = this.profile.short_profile_en
        this.form.short_profile_id = this.profile.short_profile_id
    },
    methods: {
        setNormalMode(){
            bus.$emit('normalMode', false)
        },
        setNormalModeOption(){
            if(this.$route.path == '/en'){
                if(this.form.short_profile_en != this.profile.short_profile_en){
                    $('#cancelEditModal').modal('show')
                }else{
                    this.setNormalMode()
                }
            }else{
                if(this.form.short_profile_id != this.profile.short_profile_id){
                    $('#cancelEditModal').modal('show')
                }else{
                    this.setNormalMode()
                }
            }
        },
        update(){
            this.$store.dispatch('profile/updateProfile', [this.$props.id, this.form])
        }
    },
}
</script>