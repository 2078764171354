<template>
    <div>
        <div class="modal fade" id="moreSkillsModal" tabindex="-1" aria-labelledby="moreSkillsModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="moreSkillsModalLabel">Skills</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="skill-overflow">
                            <div class="row">
                                <div class="col-md-3 col-6 skill" v-for="skill in skillsModal" :key="skill.id">
                                    <div class="col-md-12 skill-item">
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-center">
                                                <img :src="`${baseURL}/storage/images/skills/${skill.icon}`" :alt="skill.name" class="w-100">
                                            </div>
                                            <div class="col-8 d-flex align-items-center">
                                                <span>
                                                    <b>{{skill.name}} <span v-if="skill.ability">({{ skill.ability }})</span></b>
                                                    <!-- <br> 
                                                ({{ $route.path == '/en' ? skill.experience_en : skill.experience_id }}) -->
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn" data-dismiss="modal">{{ $route.path == '/en' ? 'Close' : 'Tutup' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from "../../config";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            baseURL: config.backendBaseURL,
        };
    },
    computed: {
        ...mapGetters({
            skillsModal: "skillsModal",
        }),
    },
};
</script>

<style lang="scss" scoped>
.modal-content {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    border: 0;
    color: white;
}
.close,
.mailbox-attachment-close {
    color: red !important;
    text-shadow: none;
    font-size: 50px;
}
.modal-header {
    padding: 0 15px;
    display: flex;
    align-items: center;
    border: 0;
}
.modal-body {
}
.modal-footer {
    border: 0;
    button {
        background: #e16259;
        color: white;
        font-weight: bold;
    }
}
.skill-overflow {
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
.skill {
    padding: 0 3px;
}
.skill-item {
    margin-bottom: 18px !important;
}
@media screen and (max-width: 768px) {
    .skill {
        padding: 0;
    }
    .skill-item {
        margin-bottom: 24px !important;
    }
}
</style>