<template>
    <div class="my-5" id="skills">
        <template v-if="authenticated">
            <createSkill />
            <updateSkill :skillId="skillId" v-if="skillId != null" />
        </template>
        <template v-else>
            <moreSkills />
        </template>
        <div class="row">
            <div class="col-xl-6 d-flex justify-content-center align-items-center zindex-999" data-aos="fade-up">
                <img src="@/assets/images/web-development.png" alt="Monitor" class="w-100">
            </div>
            <div class="col-xl-6 zindex-999">
                <!-- ENGLISH -->
                <template v-if="$route.path == '/en'">
                    <h3 class="content-heading" data-aos="fade-up">Hard skills that i still continue to practice</h3>
                    <div class="row">
                        <div class="col-xl-4 col-4 skill" data-aos="flip-left" data-toggle="modal" data-target="#createModal" v-if="authenticated" @click="removeFormErrors">
                            <div class="col-md-12 py-2 skill-item">
                                <div class="image">
                                    <img src="@/assets/images/icon/plus.png" alt="New Skill">
                                </div>
                                <h3 class="text-center mt-2">NEW SKILL</h3>
                                <!-- <p class="text-center">Click to add new skill</p> -->
                            </div>
                        </div>
                        <div class="col-xl-4 col-4 skill" data-aos="flip-left" v-for="skill in skills" :key="skill.id" :data-toggle="authenticated ? 'modal' : ''" :data-target="authenticated ? '#updateModal' : ''" @click="getSkillId(skill.id)">
                            <div class="col-md-12 py-2 skill-item">
                                <div class="image">
                                    <img :src="`${baseURL}/storage/images/skills/${skill.icon}`" :alt="skill.name">
                                </div>
                                <h3 class="text-center mt-2">{{ skill.name }} <span v-if="skill.ability">({{ skill.ability }})</span></h3>
                                <!-- <p class="text-center"><b>{{ skill.experience_en }}</b> of experience</p> -->
                            </div>
                        </div>
                        <div class="col-xl-4 col-4 skill" data-aos="flip-left" data-toggle="modal" data-target="#moreSkillsModal">
                            <div class="col-md-12 py-2 skill-item" v-if="!authenticated">
                                <div class="image">
                                    <img src="@/assets/images/icon/more.png" alt="See More">
                                </div>
                                <h3 class="text-center mt-2">SEE MORE</h3>
                                <!-- <p class="text-center">Click to see more skills</p> -->
                            </div>
                        </div>
                    </div>
                </template>

                <!-- INDONESIAN -->
                <template v-if="$route.path == '/id'">
                    <h3 class="content-heading" data-aos="fade-up">Hard skills yang masih terus saya latih</h3>
                    <div class="row">
                        <div class="col-xl-4 col-4 skill" data-aos="flip-left" data-toggle="modal" data-target="#createModal" v-if="authenticated" @click="removeFormErrors">
                            <div class="col-md-12 py-2 skill-item">
                                <div class="image">
                                    <img src="@/assets/images/icon/plus.png" alt="New Skill">
                                </div>
                                <h3 class="text-center mt-2">SKILL BARU</h3>
                                <!-- <p class="text-center">Klik buat bikin skill baru</p> -->
                            </div>
                        </div>
                        <div class="col-xl-4 col-4 skill" data-aos="flip-left" v-for="skill in skills" :key="skill.id" :data-toggle="authenticated ? 'modal' : ''" :data-target="authenticated ? '#updateModal' : ''" @click="getSkillId(skill.id)">
                            <div class="col-md-12 py-2 skill-item">
                                <div class="image">
                                    <img :src="`${baseURL}/storage/images/skills/${skill.icon}`" :alt="skill.name">
                                </div>
                                <h3 class="text-center mt-2">{{ skill.name }} <span v-if="skill.ability">({{ skill.ability }})</span></h3>
                                <!-- <p class="text-center"><b>{{ skill.experience_id }}</b> pengalaman</p> -->
                            </div>
                        </div>
                        <div class="col-xl-4 col-4 skill" data-aos="flip-left" data-toggle="modal" data-target="#moreSkillsModal">
                            <div class="col-md-12 py-2 skill-item" v-if="!authenticated">
                                <div class="image">
                                    <img src="@/assets/images/icon/more.png" alt="See More">
                                </div>
                                <h3 class="text-center mt-2">LIHAT SEMUA</h3>
                                <!-- <p class="text-center">Lihat lebih banyak lagi</p> -->
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import createSkill from "./createSkill";
import updateSkill from "./updateSkill";
import moreSkills from "./moreSkills";
import config from "@/config";
export default {
    data() {
        return {
            baseURL: config.backendBaseURL,
            skillId: "",
        };
    },
    mounted() {
        this.getSkills();
    },
    components: { createSkill, updateSkill, moreSkills },
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            skills: "skills",
        }),
    },
    methods: {
        getSkills() {
            this.$store.dispatch("getSkills");
        },
        getSkillId(id) {
            this.skillId = id;
        },
        removeFormErrors() {
            this.$store.dispatch("skill/removeFormErrors");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/skills.scss";
</style>