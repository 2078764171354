<template>
    <div>
        <!-- NAVBAR -->
        <nav class="main-header navbar navbar-expand navbar-white navbar-light" style="background: none !important; border-bottom: none;">
            <ul class="navbar-nav">
                <li class="nav-item">
                    <button class="btn btn-sm badge-bgColor shadow" data-widget="pushmenu">
                        <i class="fas fa-align-left mr-2"></i> Menu
                    </button>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item btn btn-sm btn-lang">
                    <router-link to="/en" title="English">EN</router-link> | <router-link to="/id" title="Indonesia">ID</router-link>
                </li>
                <li class="nav-item btn btn-sm badge-bgColor" v-if="authenticated" @click="logout">
                    <template v-if="powerOffLoading">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto;" width="20px" height="22px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" class="float-left mr-1">
                            <circle cx="50" cy="50" fill="none" stroke="#ffffff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
                                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                            </circle>
                        </svg>
                    </template>
                    <template v-else>
                        <svg data-v-41458b80="" class="svg-inline--fa fa-sign-out-alt fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path>
                        </svg>
                    </template>
                    Out
                </li>
            </ul>
        </nav>

        <!-- SIDEBAR -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <a href="#" class="brand-link">
                <img src="../assets/images/ndarudev.png" alt="Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">ndarudev</span>
            </a>
            <div class="sidebar">
                <!-- Sidebar user panel (optional) -->
                <div class="user-panel mt-3 pb-1 mb-3 d-flex">
                    <div class="image mt-2">
                        <img src="../assets/images/me.jpeg" class="img-circle elevation-2" alt="User Image">
                    </div>
                    <div class="info">
                        <a href="#" class="d-block font-weight-bold">Muhamad Ndaru</a>
                        <span class="badge badge-bgColor">Student</span>
                    </div>
                </div>

                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <!-- NAV THIS APP -->
                        <li class="nav-header font-weight-bold">NAVIGATION</li>
                        <li class="nav-item">
                            <a href="#intro" class="side-nav-link nav-link">
                                <i class="nav-icon fas fa-home"></i>
                                <p>Home</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#shortProfile" class="side-nav-link nav-link">
                                <i class="nav-icon fas fa-user"></i>
                                <p>Intro</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#skills" class="side-nav-link nav-link">
                                <i class="nav-icon fas fa-lightbulb"></i>
                                <p>Skills</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#points" class="side-nav-link nav-link">
                                <i class="nav-icon fas fa-paper-plane"></i>
                                <p>Experiences</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="#contact" class="side-nav-link nav-link">
                                <i class="nav-icon fas fa-phone-alt"></i>
                                <p>Contact Me</p>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a href="https://www.instagram.com/ndarudev/" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fas fa-layer-group"></i>
                                <p>My Projects</p>
                            </a>
                        </li>

                        <!-- NAV SOCMED -->
                        <li class="nav-header font-weight-bold">SOCIAL MEDIA</li>
                        <li class="nav-item" title="Muhamad Ndaru">
                            <a href="https://www.youtube.com/channel/UCMRIc87hN6PLf2VD0HN4NuQ" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fab fa-youtube"></i>
                                <p>Youtube</p>
                            </a>
                        </li>
                        <li class="nav-item" title="tukangilmu.com">
                            <a href="https://www.tukangilmu.com/" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fab fa-blogger"></i>
                                <p>Blogger</p>
                            </a>
                        </li>
                        <li class="nav-item" title="Muhamad Ndaru">
                            <a href="https://web.facebook.com/Ndaru21" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fab fa-facebook"></i>
                                <p>Facebook</p>
                            </a>
                        </li>
                        <li class="nav-item" title="@ndaru_hestiafin">
                            <a href="https://www.instagram.com/ndaru_hestiafin/" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fab fa-instagram"></i>
                                <p>Instagram</p>
                            </a>
                        </li>
                        <li class="nav-item" title="ndaru21">
                            <a href="https://github.com/ndaruhes" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fab fa-github"></i>
                                <p>GitHub</p>
                            </a>
                        </li>
                        <li class="nav-item" title="muhamadndaru">
                            <a href="https://www.linkedin.com/in/muhamadndaru/" target="_blank" class="side-nav-link nav-link">
                                <i class="nav-icon fab fa-linkedin-in"></i>
                                <p>LinkedIn</p>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            authenticated: "auth/authenticated",
            powerOffLoading: "auth/powerOffLoading",
        }),
    },
    methods: {
        logout() {
            this.$store.dispatch("auth/logout");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/navbar.scss";
</style>