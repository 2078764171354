<template>
    <div>
        <!-- CREATE EXPERIENCE EN -->
        <div class="collapse" id="experienceEnCollapse" v-if="$route.path == '/en'">
            <form action="#" @submit.prevent="submitExperience">
                <div class="form-row">
                    <div class="form-group col-8">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.experience}" placeholder="Type experience..." v-model="formExperience.experience">
                        <span class="float-left mt-1" v-if="btnLoading">
                            <ringWhite/>
                        </span>
                        <div class="invalid-feedback" v-if="formErrors.experience">{{formErrors.experience[0]}}</div>
                    </div>

                    <div class="form-group col-4">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.years}" placeholder="Years..." v-model="formExperience.years">
                        <div class="invalid-feedback" v-if="formErrors.years">{{formErrors.years[0]}}</div>
                    </div>
                </div>
                <button type="submit" class="d-none">Save</button>
            </form>
        </div>

        <!-- CREATE EXPERIENCE ID -->
        <div class="collapse" id="experienceIdCollapse" v-else>
            <form action="#" @submit.prevent="submitExperience">
                <div class="form-row">
                    <div class="form-group col-8">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.experience}" placeholder="Tulis pengalaman..." v-model="formExperience.experience">
                        <span class="float-left mt-1" v-if="btnLoading">
                            <ringWhite/>
                        </span>
                        <div class="invalid-feedback" v-if="formErrors.experience">{{formErrors.experience[0]}}</div>
                    </div>

                    <div class="form-group col-4">
                        <input type="text" class="form-control form-transparent" :class="{'is-invalid': formErrors.years}" placeholder="Tahun..." v-model="formExperience.years">
                        <div class="invalid-feedback" v-if="formErrors.years">{{formErrors.years[0]}}</div>
                    </div>
                </div>
                <button type="submit" class="d-none">Simpan</button>
            </form>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ringWhite from '@/components/loadings/ringWhite'
import $ from 'jquery'
export default {
    data(){
        return{
            formExperience: {
                experience: '',
                years: ''
            }
        }
    },
    components: {ringWhite},
    computed: {
        ...mapGetters({
            btnLoading: 'btnLoading',
            formErrors: 'formErrors',
        })
    },
    methods: {
        submitExperience(){
            this.$store.dispatch('experience/storeExperience', this.formExperience).then(response => {
                if(response.status === 200){
                    $('.collapse').collapse('hide')
                    this.formExperience = {}
                }
            })
        }
    }
}
</script>